import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import Helmet from "react-helmet"

import Hero from "../../components/hero--product"
import Content from "../../components/content-with-image"
import H4 from "../../components/Utility/heading4"
import CenteredCTA from "../../components/cta--centered"
import { BVLoader, BVReviews } from "@tyson-foods/react-component-library"
import Collapsable from "../../components/Collapse/Collapse"
import CTA from "../../components/cta"

export default class ProductDetailPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <React.Fragment>
        <Helmet>
          <title>
            Raised & Rooted™ Sweet Barbecue Flavored Plant Based Bites
          </title>
          <meta
            name="description"
            content="Raised & Rooted™ Sweet Barbecue Flavored Plant Based Bites are sure to bring a smile to your face. Made with plant based protein and 100% delicious!"
          />
          <meta
            itemProp="name"
            content="Sweet Barbecue Flavored Plant Based Bites "
          />
        </Helmet>
        <Layout>
          <BVLoader
            clientName="raisedandrooted"
            siteID="main_site"
            env={process.env.GATSBY_BAZAARVOICE_ENV}
          />
          <div itemScope itemType="http://schema.org/Product">
            <Hero
              heroDirection="reverse"
              heroclassName="single"
              headingTitleColor1="#006B2D"
              headingText1="Sweet Barbecue Flavored Plant Based Bites"
              headingColor1="#006B2D"
              bodyColor="#363636"
              bodyText="It’s barbecue bites done right. Raised & Rooted™ Sweet Barbecue Flavored Plant Based Bites are made with 100% plant based protein that’s tossed in a sweet yet smoky sauce—ready for you to fork."
              itemColor="#006B2D"
              valueText1="8"
              unitText1="g"
              labelText1="Protein (per serving)"
              valueText2="3"
              unitText2="g"
              labelText2="Fiber per serving"
              valueText3="33"
              unitText3="%"
              labelText3="Less Saturated Fat*"
              dataImg4="/leaf.svg"
              labelText4="Soy Free"
              dataImg5="/leaf.svg"
              labelText5="Made With Plant Based Protein"
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
              footnoteText="*Than USDA Data for White Meat Chicken Nuggets "
              footnoteColor="#006B2D"
              productID="00053400000286"
            >
              <img
                src="/raised-and-rooted-sweet-barbecue-flavored-plant-based-bites-708x721.png"
                alt="Raised And Rooted Sweet Barbecue Flavored Plant Based Bites"
              />
            </Hero>
            <Content
              ctaclassName="reverse"
              ctaBGColor="#97D700"
              bodyColor="#006B2D"
              imgSrc="/raised-and-rooted-sweet-barbecue-flavored-plant-based-bites-board-708x721.png"
              altText="Sweet Barbecue Flavored Plant Based Bites"
              headingText2="WHAT’S INSIDE?"
              headingColor2="#006B2D"
              blurb="Our Raised & Rooted™ Sweet Barbecue Flavored Plant Based Bites are made with lean pea protein and big barbecue flavor."
            >
              <h4>NUTRITION</h4>
              <p>
                8 grams of protein and 3 grams of fiber per serving, 33% less
                saturated fat*, and soy free.
              </p>
              <p>*Than USDA Data for White Meat Chicken Nuggets</p>
              <h4>TASTE</h4>
              <p>
                These pop-able bites are filled with tender plant based protein
                and covered in a sweet and smoky sauce. They’re perfect for your
                next dinner or tailgate and worthy of your favorite sports
                rivalry.
              </p>
            </Content>

            <div className="nutrition">
              <div>
                <Collapsable
                  className={"nutrition__prepared"}
                  text={"Preparation Instructions"}
                  collapsed={false}
                >
                  <p>
                    Keep frozen until ready to use. Appliances vary. Heating
                    times approximate.
                  </p>
                  <br></br>

                  <b>COVENTIONAL OVEN</b>
                  <p>1. Preheat oven to 375°F</p>
                  <p>
                    2. Arrange bites on a baking sheeting about 1-inch apart.
                  </p>
                  <p>3. Bake for 18-20 minutes or until hot.</p>

                  <b>MICROWAVE</b>
                  <p>1. Arrange 3 bites on a microwave-safe plate.</p>
                  <p>2. Microwave on High for 55-65 seconds or until hot.</p>
                  <p>3. Let stand for 1 minute, before serving.</p>
                </Collapsable>
                <br />
                <Collapsable
                  className={"nutrition__details"}
                  text={"Nutrition"}
                  collapsed={true}
                >
                  <p>2.5 Servings Per Container</p>
                  <p>Serving Size 3 pieces (84g)</p>
                  <p>Amount Per Serving</p>
                  <hr />
                  <table>
                    <tr>
                      <td colspan="2">Calories 190</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td colspan="2">% Daily Value *</td>
                    </tr>
                    <tr>
                      <td>Total Fat 9g</td>
                      <td>12%</td>
                    </tr>
                    <tr>
                      <td className="indent">Saturated Fat 1.5g</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Trans Fat 0g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Polyunsurated Fat 5g
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Monounsurated Fat 2.5g
                      </td>
                    </tr>
                    <tr>
                      <td>Cholesterol 0mg</td>
                      <td>0%</td>
                    </tr>
                    <tr>
                      <td>Sodium 510mg</td>
                      <td>22%</td>
                    </tr>
                    <tr>
                      <td>Total Carbohydrate 20g</td>
                      <td>7%</td>
                    </tr>
                    <tr>
                      <td className="indent">Dietary Fiber 3g</td>
                      <td>11%</td>
                    </tr>
                    <tr>
                      <td colspan="2" className="indent">
                        Total Sugars 5g
                      </td>
                    </tr>
                    <tr>
                      <td className="indent">Includes 4g Added Sugars</td>
                      <td>8%</td>
                    </tr>
                    <tr>
                      <td>Protein 8g</td>
                      <td>12%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <hr />
                      </td>
                    </tr>
                    <tr>
                      <td>Vitamin D 0.2mcg</td>
                      <td>2%</td>
                    </tr>
                    <tr>
                      <td>Calcium 130mg</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Iron 1.8mg</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Potassium 240mg</td>
                      <td>6%</td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        *The % Daily Value tells you how much a nutrient in a
                        serving of food contributes to a daily diet. 2,000
                        calories a day is used for general nutrition advice.
                      </td>
                    </tr>
                  </table>
                </Collapsable>
              </div>
              <Collapsable
                className={"nutrition__ingredients"}
                text={"Ingredients"}
                collapsed={true}
              >
                <p>
                  VEGAN FIBERS (WATER, PEA PROTEIN ISOLATE, CANOLA OIL, SODIUM
                  ALGINATE, CONTAINS 2% OR LESS OF: CITRUS FIBER AND CALCIUM
                  CHLORIDE, WATER, ENRICHED WHEAT FLOUR (ENRICHED WITH NIACIN,
                  REDUCED IRON, THIAMINE MONONITRATE, RIBOFLAVIN, FOLIC ACID),
                  WHEAT FLOUR, SUGAR, PEA PROTEIN CONCENTRATE, TOMATO PASTE,
                  CONTAINS 2% OR LESS: BARLEY MALT FLOUR, CARAMEL COLOR,
                  DEHYDRATED GARLIC, DEHYDRATED ONION, DEXTROSE, EXTRACTIVES OF
                  PAPRIKA, FLAXSEED, GARLIC POWDER, HORSERADISH POWDER,
                  LEAVENING (SODIUM ACID, PYROPHOSPHATE, SODIUM BICARBONATE,
                  MALTODEXTRIN, METHYLCELLULOSE, MODIFIED CORN STARCH, MODIFIED
                  WHEAT STARCH, MOLASSES, NATURAL FLAVOR, NATURAL FLAVOR, DARK
                  MEAT TYPE [MALTODEXTRIN (FROM CORN), SALT, NATURAL FLAVOR,
                  YEAST EXTRACT, PALM OIL, CARROT FIBER, CITRIC ACID, MEDIUM
                  CHAIN TRIGLYCERIDES, ONION POWDER, TORULA YEAST, ONION JUICE
                  CONCENTRATE, SPICE, CANOLA OIL], NATURAL SMOKE FLAVOR, OAT
                  FIBER, ONION POWDER, PALM OIL, PAPRIKA, PINEAPPLE JUICE
                  CONCENTRATE, ROSEMARY EXTRACT, SALT, SEA SALT, SMOKE
                  FLAVORING, SOYBEAN OIL, SPICES (INCLUDING CELERY SEED),
                  SUNFLOWER OIL, TAPIOCA MALTODEXTRIN, TOMATO POWDER, TORULA
                  YEAST, VINEGAR, VITAL WHEAT GLUTEN, WHEAT GLUTEN, YEAST, YEAST
                  EXTRACT.
                </p>
                <p>CONTAINS WHEAT</p>
              </Collapsable>
            </div>
            <div id="reviews" className="reviews">
              <BVReviews productID="00053400000286" />
            </div>
            <CenteredCTA
              ctaBottomMargin="30px"
              bgImage="/paper-background.jpg"
              badgeSrc="/hover.svg"
              headingText2="Where to Buy Us"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}
